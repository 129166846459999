import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Container, ButtonGroup, Button, Box } from '@material-ui/core';

import Users from './Users';
import Quote from './Quote';
import Quotation from './Quotation';
import Memo from './Memo';
import Billing from './Billing';
import Bill from './Bill';

export default function Sales (props) {

    const loc = props.location.pathname.split('/')[2];
    const { rol } = props

    return (
        <Container>
            <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                <Button style={{ cursor: 'default' }} variant="contained">Usuarios</Button>
            </ButtonGroup>
            <Box paddingY="1rem">
                <Switch>
                    <Route path="/" render={props => <Users {...props} rol={rol} />} exact={true} />
                    {/* <Route path="/usuarios/cotizador" component={Quote} exact={true} />
                    <Route path="/usuarios/cotizador/:id" component={Quotation} exact={true} />
                    <Route path="/usuarios/minuta" component={Memo} exact={true} />
                    <Route path="/usuarios/facturacion" component={Billing} exact={true} />
                    <Route path="/usuarios/facturacion/:id" component={Bill} exact={true} /> */}
                </Switch>
            </Box>
        </Container>
    )
}