import React, { useEffect, useState } from 'react';
import firebase from '../utils/firebase';
import { Typography, List, ListItem, ListItemText, Grid, TextField, Divider, Button, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, Box } from '@material-ui/core';
import { css } from '@emotion/core';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';

export default function Cart (props) {

    const id = props.match.params.id

    const [order,setorder] = useState({})

    useEffect(() => {
        firebase.getDocument('payments',id).onSnapshot(snap => {
            setorder(snap.data())
        })
    },[])

    // const prods = order.products ? order.products : [];

    // const total = _.reduce(prods, (sum,n) => {
    //     return sum + (n.price*n.quantity)
    // },0)

    return (
        <>
            <Typography variant="h4" component="h1">Detalle de Carrito</Typography>
            <Divider
                css={css`
                    margin-top: 1rem;
                `}
            />
            <List>
                <ListItem>
                    <ListItemText
                        primary="Fecha"
                        secondary={moment(order.timestamp).format('DD-MM-YYYY')}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Fecha de Servicio"
                        secondary={moment(order.date).format('DD-MM-YYYY')}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Horario"
                        secondary={order.hour}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Tipo"
                        secondary={order.type}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Medidas"
                        secondary={order.size}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Nombre de Cliente"
                        secondary={order.name}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Email de Cliente"
                        secondary={order.email}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Teléfono de Cliente"
                        secondary={order.phone}
                    />
                </ListItem>
            </List>
            {/* <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Producto</TableCell>
                        <TableCell>Precio Unitario</TableCell>
                        <TableCell>Cantidad</TableCell>
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prods.map((item,index) => <TableRow key={index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{numeral(item.price).format('$0,0.00')}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{numeral(item.price*item.quantity).format('$0,0.00')}</TableCell>
                    </TableRow>)}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan="2"></TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell>{numeral(total).format('$0,0.00')}</TableCell>
                    </TableRow>
                </TableFooter>
            </Table> */}
        </>
    )
}