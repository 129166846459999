import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';
import { Link } from 'react-router-dom';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Modal from '../components/Modal';

export default function PurchasesProviders () {

    const [providers,setproviders] = useState([])
    // const [open, setOpen] = React.useState(false);

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    useEffect(() => {
        firebase.getCollection('users').where('isProvider','==',true).onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setproviders(data)
        })
    },[])

    return (
        <>
            <Typography variant="h3" color="secondary" component="h1">Proveedores</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Teléfono</TableCell>
                        <TableCell>Página Web</TableCell>
                        <TableCell>Ver</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        providers.map(item => <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.phone}</TableCell>
                            <TableCell>{item.webPage}</TableCell>
                            <TableCell><IconButton component={Link} to={`/compras/proveedores/${item.id}`} color="primary"><ArrowForwardIosIcon /></IconButton></TableCell>
                        </TableRow>)
                    }
                </TableBody>
            </Table>
        </>
    )
}