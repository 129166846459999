import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, Grid, TextField, Select, Paper } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { css } from '@emotion/core'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

const Item = ({ data,products }) => {

    const product = _.find(products, o => {
        return o.id === data.productId
    })

    return (
        <TableRow>
            <TableCell>{moment(data.timestamp).format('DD-MM-YYYY hh:mm a')}</TableCell>
            <TableCell>{product ? product.name : ''}</TableCell>
            <TableCell>{data.reference}</TableCell>
            <TableCell>{data.type}</TableCell>
            <TableCell>{numeral(data.quantity).format('0,0.00')}</TableCell>
        </TableRow>
    )
}

export default function WharehouseMoves () {
    const [products,setproducts] = useState([])
    const [moves,setmoves] = useState([])

    const [selected,setselected] = useState({})
    const [reference,setreference] = useState('')
    const [quantity,setquantity] = useState('')
    const [type,settype] = useState('')

    useEffect(() => {
        firebase.getCollection('product-moves').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setmoves(data)
        })
        firebase.getCollection('products').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setproducts(data)
        })
    },[])

    return (
        <>
            <Paper css={css`
                padding: 2rem;
            `}>
                <Grid alignItems="flex-end" container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={products}
                            getOptionLabel={(option) => option.name}
                            onChange={(e,value) => setselected(value)}
                            renderInput={(params) => <TextField {...params} label="Producto" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Referencia/Comentariois"
                            value={reference}
                            onChange={e => setreference(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Cantidad"
                            value={quantity}
                            onChange={e => setquantity(e.target.value)}
                            variant="outlined"
                            fullWidth
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="body1">Tipo</Typography>
                        <Select
                            native
                            value={type}
                            onChange={e => settype(e.target.value)}
                            fullWidth
                        >
                            <option value="">** Selecciona un tipo **</option>
                            <option value="Entrada">Entrada</option>
                            <option value="Salida">Salida</option>
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button onClick={handleSubmit} size="large" variant="outlined" color="secondary" fullWidth>Agregar</Button>
                    </Grid>
                </Grid>
            </Paper>
            <Table css={css`
                margin-top: 2rem;
            `}>
                <TableHead>
                    <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Producto</TableCell>
                        <TableCell>Referencia</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Cantidad</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        moves.map(item => <Item key={item.id} products={products} data={item} />)
                    }
                </TableBody>
            </Table>
        </>
    )

    async function handleSubmit () {

        try {
            if (selected && quantity && type) {
                const item = {
                    productId: selected.id,
                    quantity: Number(quantity),
                    type,
                    reference
                }
                firebase.simpleAdd(item,`product-moves`).then(() => {
                    alert('Movimiento agregado')
                    setquantity('')
                    settype('')
                    setreference('')
                })
            } else {
                alert('Selecciona un producto, cantidad y tipo para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}