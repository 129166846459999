import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import { Container, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'

export default function Wharehouse () {

    const [products,setproducts] = useState([])

    useEffect(() => {
        firebase.getCollection('products').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setproducts(data)
        })
    },[])

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Producto</TableCell>
                        <TableCell>Entradas</TableCell>
                        <TableCell>Salidas</TableCell>
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        products.map(item => <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>0</TableCell>
                            <TableCell>0</TableCell>
                            <TableCell>0</TableCell>
                        </TableRow>)
                    }
                </TableBody>
            </Table>
        </Container>
    )
}