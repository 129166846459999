import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';
import { Link } from 'react-router-dom';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Paper, Grid, TextField, Button } from '@material-ui/core';
import axios from 'axios';
import { css } from '@emotion/core';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function Users (props) {

    const [providers,setproviders] = useState([])
    const [name,setname] = useState('')
    const [email,setemail] = useState('')
    const [phone,setphone] = useState('')

    useEffect(() => {
        firebase.getCollection('users').orderBy('name').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setproviders(data)
        })
    },[])

    return (
        <>
            <Typography variant="h4" color="secondary" component="h1">Usuarios</Typography>
            {/* <Paper css={css`
                padding: 1rem;
                margin: 1.5rem 0;
            `}>
                <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Nombre"
                            fullWidth
                            variant="outlined"
                            value={name}
                            onChange={e => setname(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Email"
                            fullWidth
                            variant="outlined"
                            value={email}
                            onChange={e => setemail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Teléfono"
                            fullWidth
                            variant="outlined"
                            value={phone}
                            onChange={e => setphone(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button onClick={handleSubmit} color="secondary" fullWidth variant="contained">Agregar Usuario</Button>
                    </Grid>
                </Grid>
            </Paper> */}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Teléfono</TableCell>
                        <TableCell>Rol</TableCell>
                        {/* <TableCell>Ver</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        providers.map(item => <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.phone}</TableCell>
                            <TableCell>
                                <select disabled={props.rol === 'agent' ? true : false} value={item.rol} onChange={e => firebase.update('users',item.id,'rol',e.target.value)}>
                                    <option value="user">Usuario</option>
                                    <option value="admin">Administrador</option>
                                    {/* <option value="agent">Comisionista</option> */}
                                </select>
                            </TableCell>
                            {/* <TableCell><IconButton component={Link} to={`/usuarios/${item.id}`} color="primary"><ArrowForwardIosIcon /></IconButton></TableCell> */}
                        </TableRow>)
                    }
                </TableBody>
            </Table>
        </>
    )

    async function handleSubmit () {
        try {
            const item = {
                name,
                email,
                phone
            }
            firebase.simpleAdd(item,'users').then(id => {
                props.location.push(`/users/${id}`)
            })
        } catch (error) {
            alert(error.message)
        }
    }
}