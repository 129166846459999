import React, { useEffect, useState } from 'react'
import firebase from './utils/firebase'
import { Switch, Route, Link } from 'react-router-dom'
import { Divider, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, IconButton, Typography, Hidden, Drawer } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import BusinessIcon from '@material-ui/icons/Business';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MessageIcon from '@material-ui/icons/Message';

import Loader from './components/Loader';
import Purchases from './pages/Purchases';
import Sales from './pages/Sales';
import Products from './pages/Products';
import Product from './pages/Product';
import User from './pages/User';
import Inventory from './pages/Inventory';
import Accounting from './pages/Accounting';
import Configuration from './pages/Configuration';
import Categories from './pages/Categories';
import Carts from './pages/Carts';
import Cart from './pages/Cart';
import Payments from './pages/Payments';
import Messages from './pages/Messages';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
}));

export default function App (props) {

    const loc = props.location.pathname.split('/')[1]
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [user,setUser] = useState(undefined)

    useEffect(() => {
        firebase.isInitialized().then(user => {
            if (user) {
                firebase.userSession().then(data => {
                    if (data.rol === "admin") {
						setUser(data)
                    } else {
                        firebase.logout().then(() => {
                            alert('No tienes permisos para ingresar')
							props.history.push('/iniciar-sesion')
						})
                    }
                })
            } else {
				props.history.push('/iniciar-sesion')
			}
        })
    },[props.history])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                {/* <ListItem selected={loc === '' || loc === undefined ? true : false} component={Link} to="/" button>
                    <ListItemIcon><DashboardIcon/></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem> */}
                <ListItem selected={loc === undefined || loc === '' ? true : false} component={Link} to="/" button>
                    <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                    <ListItemText primary="Usuarios" />
                </ListItem>
                <ListItem selected={loc === 'clientes' ? true : false} component={Link} to="/clientes" button>
                    <ListItemIcon><BusinessIcon/></ListItemIcon>
                    <ListItemText primary="Clientes" />
                </ListItem>
                <ListItem selected={loc === 'contabilidad' ? true : false} component={Link} to="/contabilidad" button>
                    <ListItemIcon><AccountBalanceWalletIcon/></ListItemIcon>
                    <ListItemText primary="Contabilidad" />
                </ListItem>
                {/* <ListItem selected={loc === 'inventario' ? true : false} component={Link} to="/inventario" button>
                    <ListItemIcon><ListAltIcon/></ListItemIcon>
                    <ListItemText primary="Inventario" />
                </ListItem> */}
            </List>
            <Divider />
            <List>
                {/* <ListItem selected={loc === 'carritos' ? true : false} component={Link} to="/carritos" button>
                    <ListItemIcon><AddShoppingCartIcon/></ListItemIcon>
                    <ListItemText primary="Carritos Creados" />
                </ListItem> */}
                <ListItem selected={loc === 'mensajes' ? true : false} component={Link} to="/mensajes" button>
                    <ListItemIcon><MessageIcon/></ListItemIcon>
                    <ListItemText primary="Mensajes" />
                </ListItem>
                <ListItem selected={loc === 'pagos' ? true : false} component={Link} to="/pagos" button>
                    <ListItemIcon><PaymentIcon/></ListItemIcon>
                    <ListItemText primary="Pagos" />
                </ListItem>
                {/* <ListItem selected={loc === 'configuracion' ? true : false} component={Link} to="/configuracion" button>
                    <ListItemIcon><SettingsIcon/></ListItemIcon>
                    <ListItemText primary="Configuración" />
                </ListItem> */}
            </List>
        </div>
    )

    return user ? (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={{ color: '#fff', fontWeight: '700' }} variant="h6" noWrap>
                        Panel de Administrador
                    </Typography>
					<IconButton onClick={logOut} style={{ marginLeft: 'auto' }}>
						<ExitToAppIcon/>
					</IconButton>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    <Route path="/" component={Sales} exact={true} />
                    {/* <Route path="/usuarios" render={props => <Sales {...props} rol={user.rol} />} /> */}
                    <Route path="/contabilidad" component={Accounting} />
                    <Route path="/compras" component={Purchases} />
                    <Route path="/clientes" component={Products} exact={true} />
                    <Route path="/clientes/:id" component={Product} exact={true} />
                    <Route path="/usuarios/:id" component={User} exact={true} /> 
                    <Route path="/inventario" component={Inventory} />
                    <Route path="/configuracion" component={Configuration} exact={true} />
                    <Route path="/configuracion/categorias" component={Categories} exact={true} />
                    <Route path="/pagos" component={Payments} exact={true} />
                    <Route path="/carritos" component={Carts} exact={true} />
                    <Route path="/mensajes" component={Messages} exact={true} />
                    <Route path="/carritos/:id" component={Cart} exact={true} />
                </Switch>
            </main>
        </>
    ) : <Loader />


	async function logOut () {
		try {
			firebase.logout().then(() => {
				props.history.push('/iniciar-sesion')
			})
		} catch (error) {
			alert(error.message)
		}
	}
}