import React, { useEffect, useState } from 'react';
import firebase from '../utils/firebase';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import _ from 'lodash';

import Loader from '../components/Loader';

export default function Provider (props) {

    const id = props.match.params.id
    const [user,setuser] = useState(null)
    const [edited,setedited] = useState(null)

    useEffect(() => {
        firebase.getDocument('users',id).onSnapshot(snap => {
            setuser(snap.data())
            setedited(snap.data())
        })
    },[id])

    const handleChange = (index,value) => {
        var newItem = {...edited}
        newItem[index] = value
        setedited(newItem)
    }

    return user && edited ? (
        <>
            <Typography variant="h4" component="h1" color="secondary">Detalle de Proveedor</Typography>
            <Grid style={{ marginTop: '1rem' }} container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Nombre"
                        value={edited.name}
                        onChange={e => handleChange('name',e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Email"
                        value={edited.email}
                        disabled
                        onChange={e => handleChange('email',e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Teléfono"
                        value={edited.phone}
                        onChange={e => handleChange('phone',e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Página Web"
                        value={edited.webPage}
                        onChange={e => handleChange('webPage',e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Dirección"
                        value={edited.address}
                        onChange={e => handleChange('address',e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Datos de Pago"
                        value={edited.payment}
                        onChange={e => handleChange('payment',e.target.value)}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows="4"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Comentarios"
                        value={edited.comments}
                        onChange={e => handleChange('comments',e.target.value)}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows="4"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={handleSubmit} variant="contained" fullWidth disabled={_.isEqual(edited,user)} color="secondary">Guardar Cambios</Button>
                </Grid>
            </Grid>
        </>
    ) : <Loader />

    async function handleSubmit () {
        try {
            firebase.getDocument('users',id).update(edited).then(() => {
                alert('Proveedor actualizado')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}