import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import { Container, Grid, Typography, TextField, Button, Table, TableHead, TableRow, TableCell, Divider, Paper, ListItem, ListItemText, ListItemSecondaryAction, IconButton, List, Box } from '@material-ui/core'
import { css } from '@emotion/core'
import _ from 'lodash'

import DeleteIcon from '@material-ui/icons/Delete';

const Sub = ({ data, index, subs, id }) => {

    return (
        <Box display="flex">
            <TextField
                fullWidth
                value={data}
                onChange={e => handleChange(e.target.value)}
            />
            <IconButton css={css`
                margin-left: 1rem;
            `} onClick={handleDelete} color="primary">
                <DeleteIcon />
            </IconButton>
        </Box>
    )

    async function handleChange (val) {
        try {
            var newList = [...subs]
            newList[index] = val
            firebase.update('categories',id,'subs',newList)
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleDelete () {
        try {
            var newList = [...subs]
            delete newList[index]
            const filtered = _.filter(newList, o => {
                return o
            })
            firebase.update('categories',id,'subs',filtered)
        } catch (error) {
            alert(error.message)
        }
    }
}

const Item = ({ data }) => {

    const subs = data.subs ? data.subs : []

    return (
        <Grid item xs={12} md={6}>
            <Paper css={css`
                padding: 1rem;
                position: relative;
            `}>
                <IconButton onDoubleClick={() => firebase.delete('categories',data.id,data)} color="primary" css={css`
                    position: absolute;
                    top: .1rem;
                    right: .1rem;
                `}>
                    <DeleteIcon/>
                </IconButton>
                <picture css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 95%;
                    }
                `}>
                    <img src={data.cover ? data.cover : '/images/no-image.jpg'} alt={data.name}/>
                </picture>
                <TextField
                    fullWidth
                    variant="outlined"
                    onChange={e => firebase.addFileDoc('categories',data.id,'cover',e.target.files[0])}
                    type="file"
                />
                <TextField
                    value={data.name}
                    fullWidth
                    variant="outlined"
                    onChange={e => firebase.update('categories',data.id,'name',e.target.value)}
                    css={css`
                        margin-top: 1rem;
                    `}
                />
                <Divider css={css`
                    margin: 1rem 0;
                `}/>
                <Box display="flex" flexDirection="column">
                    {
                        subs.map((item,index) => <Sub key={index} index={index} data={item} id={data.id} subs={subs} />)
                    }
                </Box>
                <Button css={css`
                    margin-top: 1rem;
                `} onClick={handleAdd} fullWidth variant="outlined" color="secondary">Agregar subcategoría</Button>
            </Paper>
        </Grid>
    )

    async function handleAdd () {
        try {
            var newList = [...subs]
            newList.push('')
            firebase.update('categories',data.id,'subs',newList)
        } catch (error) {
            alert(error.message)
        }
    }
}

export default function Categories () {

    const [name,setname] = useState('')

    const [categories,setcategories] = useState([])

    useEffect(() => {
        firebase.getCollection('categories').onSnapshot(snap => {
            const data = snap.docs.map(item => item.data())
            setcategories(data)
        })
    },[])

    return (
        <Container maxWidth="md">
            <Paper css={css`
                padding: 1rem;
            `}>
                <Grid alignItems="center" spacing={3} container>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Nombre de Categoría"
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={e => setname(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button size="large" color="secondary" onClick={handleSubmit} size="large" variant="contained" fullWidth>Agregar</Button>
                    </Grid>
                </Grid>
            </Paper>
            <Divider css={css`
                margin: 1rem 0;
            `} />
            <Grid spacing={3} container>
                {categories.map(item => <Item key={item.id} data={item} />)}
            </Grid>
        </Container>
    )


    async function handleSubmit () {
        try {
            if (name) {
                const item = {
                    name
                }
                firebase.simpleAdd(item,'categories').then(() => {
                    alert('Categoría agregada')
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }
}