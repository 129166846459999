import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from '../utils/firebase';
import { Container, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const Item = ({ data }) => {

    return (
        <TableRow>
            <TableCell>{moment(data.timestamp).format('DD-MM-YYYY')}</TableCell>
            <TableCell>{moment(data.date).format('DD-MM-YYYY')}</TableCell>
            <TableCell>{data.name ? data.name : ' - '}</TableCell>
            <TableCell>{data.email ? data.email : ' - '}</TableCell>
            <TableCell>{numeral(data?.session?.amount_total).format('$0,0.00')}</TableCell>
            <TableCell align="center"><IconButton component={Link} to={`/carritos/${data.id}`} color="secondary"><ArrowForwardIosIcon/></IconButton></TableCell>
        </TableRow>
    )
}

export default function Payments () {

    const [payments,setpayments] = useState([])

    useEffect(() => {
        firebase.getCollection('payments').orderBy('timestamp','desc').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setpayments(data)
        })
    },[])

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Fecha de Servicio</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell align="center">Ver</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        payments.map(item => <Item key={item.id} data={item} />)
                    }
                </TableBody>
            </Table>
        </Container>
    )
}