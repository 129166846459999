import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Container, ButtonGroup, Button, Box } from '@material-ui/core';
import Wharehouse from './Wharehouse';
import WharehouseMoves from './WharehouseMoves';

export default function Inventory (props) {

    const loc = props.location.pathname.split('/')[2];

    return (
        <Container>
            <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                <Button style={{ cursor: 'default' }} variant="contained">Inventario</Button>
                <Button disabled={loc === undefined ? true : ''} component={Link} to="/inventario">En Almacén</Button>
                <Button disabled={loc === 'movimiento' ? true : ''} component={Link} to="/inventario/movimiento">Movimiento de Mercancía</Button>
            </ButtonGroup>
            <Box paddingY="1rem">
                <Switch>
                    <Route path="/inventario" component={Wharehouse} exact={true} />
                    <Route path="/inventario/movimiento" component={WharehouseMoves} exact={true} />
                </Switch>
            </Box>
        </Container>
    )
}