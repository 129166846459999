import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Grid, Typography, Paper } from '@material-ui/core';
import { css } from '@emotion/core';

import CategoryIcon from '@material-ui/icons/Category';

export default function Configuration () {
    

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Paper component={Link} to="/configuracion/categorias" css={css`
                        padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                    `}>
                        <CategoryIcon css={css`
                            font-size: 2.5rem;
                            margin-bottom: 1rem;
                        `}/>
                        <Typography variant="h5" align="center">Categorías</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}