import React, { useEffect, useState } from 'react';
import firebase from '../utils/firebase';
import { Typography, Grid, TextField, Button, FormControl, InputLabel, Select, GridList, GridListTile, GridListTileBar, IconButton } from '@material-ui/core';
import { css } from '@emotion/core';
import _ from 'lodash';

import Loader from '../components/Loader';

import DeleteIcon from '@material-ui/icons/Delete';

export default function Product (props) {

    const id = props.match.params.id
    const [product,setproduct] = useState(null)
    const [edited,setedited] = useState(null)
    const [cover,setcover] = useState(null)
    const [image,setimage] = useState(null)
    const [categories,setcategories] = useState([])
    const [images,setimages] = useState([])

    useEffect(() => {
        firebase.getDocument('products',id).onSnapshot(snap => {
            setproduct(snap.data())
            setedited(snap.data())
        })
        firebase.getCollection('categories').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setcategories(data)
        })

        firebase.getCollection('images').where('productId','==',id).onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setimages(data)
        })
    },[id])

    const handleChange = (index,value) => {
        var newItem = {...edited}
        newItem[index] = value
        setedited(newItem)
    }

    const subs = product ? product.category ?  _.find(categories, o => {
        return o.id === product.category
    }) ? _.find(categories, o => {
        return o.id === product.category
    }).subs : null : null : null

    return product && edited ? (
        <>
            <Typography variant="h4" component="h1" color="secondary">Detalle de Usuario</Typography>
            <Grid alignItems="center" style={{ marginTop: '1rem' }} container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Nombre"
                        value={edited.name}
                        onChange={e => handleChange('name',e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button size="large" onClick={() => firebase.update('products',product.id,'isActive',product.isActive ? !product.isActive : true)} variant="contained" fullWidth color="primary">{product.isActive === true ? 'Activo' : 'Inactivo'}</Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Descripción"
                        value={edited.description}
                        onChange={e => handleChange('description',e.target.value)}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows="4"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={handleSubmit} variant="contained" fullWidth disabled={_.isEqual(edited,product)} color="secondary">Guardar Cambios</Button>
                </Grid>
            </Grid>
            <Grid css={css`
                margin-top: 1rem;
            `} container spacing={3}>
                <Grid item xs={12} md={3}>
                    {product.cover ? <picture>
                        <img css={css`
                            width: 95%;
                        `} src={product.cover} alt={product.name} />
                    </picture> : ''}
                    <TextField
                        label="Imagen de portada"
                        onChange={e => setcover(e.target.files[0])}
                        fullWidth
                        type="file"
                        style={{ marginTop: '1rem' }}
                    />
                    <Button onClick={handleCover} style={{ marginTop: '1rem' }} variant="outlined" color="secondary" fullWidth>Establecer Portada</Button>
                </Grid>
            </Grid>
        </>
    ) : <Loader />

    async function handleImage () {
        try {
            if (image) {
                const item = {
                    productId: product.id,
                }
                firebase.addNew(image,item).then(() => {
                    alert('Imagen subida')
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleCover () {
        try {
            if (cover) {
                firebase.addFileDoc('products',id,'cover',cover).then(() => {
                    alert('Portada establecida')
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleSubmit () {
        try {
            firebase.getDocument('products',id).update(edited).then(() => {
                alert('Producto actualizado')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}