import { createMuiTheme } from '@material-ui/core/styles';
import { red, green, lime } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createMuiTheme({
	palette: {
        type: "dark",
		primary: green,
		secondary: lime,
		error: {
			main: red.A400,
		},
	},
});

export default theme;