import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';
import { Link } from 'react-router-dom';
import { Container, ButtonGroup, Button, Box, Table, TableHead, TableRow, TableCell, TableBody, Typography, TextField, IconButton } from '@material-ui/core';
import { css } from '@emotion/core';
import numeral from 'numeral';
import _ from 'lodash';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';

import Modal from '../components/Modal';

const Item = ({ data }) => {

    return (
        <TableRow>
            <TableCell>
                <IconButton color="primary" onDoubleClick={() => firebase.delete('products',data.id,data)}><DeleteIcon /></IconButton>
            </TableCell>
            <TableCell><img css={css`
                width: 5rem;
            `} src={data.cover} alt={data.name} /></TableCell>
            <TableCell>{data.name}</TableCell>
            <TableCell>{data.isActive === true ? 'Activo' : 'Inactivo'}</TableCell>
            <TableCell><IconButton component={Link} to={`/clientes/${data.id}`} color="primary"><ArrowForwardIosIcon/></IconButton></TableCell>
        </TableRow>
    )
}

export default function Products () {

    const [open, setOpen] = useState(false);
    const [product,setproduct] = useState({});
    const [products,setproducts] = useState([])
    const [category,setcategory] = useState(null);
    const [categories,setcategories] = useState([]);
    const [seeker,setSekker] = useState('')

    useEffect(() => {
        firebase.getCollection('products').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setproducts(data)
        })
        firebase.getCollection('categories').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setcategories(data)
        })
    },[])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (index,val) => {
        const newItem = {...product}
        newItem[index] = val
        setproduct(newItem)
    }

    const filt = _.filter(products, o => {
        return o.category === category
    })
    const noCategory = _.filter(products, o => {
        return !o.category
    })

    const filtered = category ? (category === 'no-category' ? noCategory : filt) : products;

    const search = seeker ? _.filter(filtered, item => {
        return _.lowerCase(item.name + ' ' + item.price + ' ' + item.sub + ' ' + item.description + ' ' + item.cost).match(_.lowerCase(seeker))
    }) : filtered

    return (
        <Container>
            <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleOpen} variant="contained" color="secondary">+ Agregar Cliente</Button>
            </Box>
            {/* <ButtonGroup size="small" css={css`
                margin-top: 1.5rem;
            `} color="primary">
                <Button variant={!category ? 'contained' : ''} onClick={() => setcategory(null)}>Todos</Button>
                {
                    categories.map(item => <Button variant={category === item.id ? 'contained' : ''} key={item.id} onClick={() => setcategory(item.id)}>{item.name}</Button>)
                }
                <Button variant={category === 'no-category' ? 'contained' : ''} onClick={() => setcategory('no-category')}>Sin Categoría</Button>
            </ButtonGroup> */}
            <TextField
                fullWidth
                variant="outlined"
                value={seeker}
                onChange={e => setSekker(e.target.value)}
                size="small"
                label="Buscar ..."
                css={css`
                    margin-top: 1rem;
                `}
            />
            <Table style={{ marginTop: '1rem' }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: '600' }} align="center" variant="head">Eliminar</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="center" variant="head">Portada</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="center" variant="head">Nombre de Cliente</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="center" variant="head">Estatus</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="center" variant="head">Ver</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        search.map(item => <Item key={item.id} data={item} />)
                    }
                </TableBody>
            </Table>
            <Modal open={open} handleClose={handleClose}>
                <Typography color="primary" align="center" variant="h4" component="h2">Nuevo Cliente</Typography>
                <TextField
                    label="Nombre de Cliente"
                    value={product.name}
                    onChange={e => handleChange('name',e.target.value)}
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: '1rem' }}
                />
                <TextField
                    label="Descripción de Cliente"
                    value={product.description}
                    onChange={e => handleChange('description',e.target.value)}
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: '1rem' }}
                    multiline
                    rows="3"
                />
                <Button onClick={handleAdd} fullWidth variant="outlined" style={{ marginTop: '1rem' }} color="secondary">Agregar</Button>
            </Modal>
        </Container>
    )

    async function handleAdd () {
        try {
            if (product) {
                firebase.simpleAdd(product,'products').then(() => {
                    alert('Cliente agregado')
                    handleClose()
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }
}